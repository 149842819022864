import React from 'react';
import styled from 'styled-components';

const LinkWrapper = styled.a`
    text-decoration: none;
    padding: 3.2rem;
    box-shadow: 1px 7px 10px rgba(0,0,0,0.05);
    margin: 0 1rem;
    flex: 1 0 100%;
    max-width: 320px;

    @media (min-width: 576px) {
        flex: 1 0 50%;
    }
    @media (min-width: 768px) {
        flex: 1 0 33%;
    }
`;

const PostTile = ({ node }) => (
    <LinkWrapper href={node.frontmatter.path}>
      <div >
        <img src={node.frontmatter.pageImage} alt={node.frontmatter.title}/>
        <h2>{node.frontmatter.title}</h2>
        <h3>{node.frontmatter.date}</h3>
        <p>{node.excerpt}</p>
      </div>
    </LinkWrapper>
)

export default PostTile;
