import React from "react"
import { graphql } from "gatsby"

import Master from "../layout/_master"
import SEO from "../components/seo"
import PostTile from "../components/blog/postTile"

const SecondPage = ({ data }) => (
  <Master>
    <SEO title="Page two" />
    <h1>My personal diary!</h1>
    <h2>As you can see it leaves much to be desired</h2>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
      {data.allMarkdownRemark.edges.map(({ node }) => (<PostTile node={node} />))}
    </div>
  </Master>
)

export const pageQuery = graphql`
  query blogListQuery {
    allMarkdownRemark
    (sort: { fields: [frontmatter___date], order: DESC })
    {
      edges{
        node{
          excerpt
          frontmatter{
            path
            pageImage
            title
            date
          }
        }
      }
    }
  }
`

export default SecondPage
